<template>
  <main class="page-my-profile page-edit-my-profile">
    <PageTitleContainer>
      <PageTitle removeMargin>Modifica dati</PageTitle>

      <div class="mb-3 text-right">
        <router-link class="btn btn-outline-secondary" :to="{ name: 'profile' }">Torna indietro</router-link>
      </div>
    </PageTitleContainer>

    <div class="container">
        <UserForm v-if="user" :user="user" :feedback.sync="feedback" @submitForm="updateUser" edit />
    </div>
  </main>
</template>

<script>

import $api from '../../../libs/Api/services/api';
import rolesMixin from '../../../mixins/roles';

export default {
  name: 'my-profile-edit',
  mixins: [rolesMixin],
  components: {
    PageTitle: () => import('../../components/Layout/PageTitle'),
    PageTitleContainer: () => import('../../components/Layout/PageTitleContainer'),
    UserForm: () => import('../../components/Form/UserForm'),
  },
  data () {
    return {
      user: null,
      feedback: null,
    };
  },
  methods: {
    success () {
      this.feedback = true;
      this.$router.replace({ name: 'profile' });
      this.$oauth2.getMe(true);
    },
    failed (err) {
      this.feedback = false;
      this.$log.error(err);
    },
    updateUser (userForm) {
      const user = { ...userForm };

      this.$api.updateMyProfile(user)
        .then(this.success)
        .catch(this.failed)
      ;
    },
  },
  async beforeRouteEnter (to, from, next) {
    try {
      const res = await Promise.all([
        $api.getMyProfile(),
      ]);
      return next(vm => {
        vm.user = res[0]?.data || {};
      });
    } catch (error) {
      return next(vm => vm.$log.error(error));
    }
  },
};

</script>
